import { useQuery } from 'react-query'
import { IUser } from '~/utils/types'
import { api } from './api'

export const getUserPermissions = async (): Promise<string[]> => {
  const response = await api().get(`list-permissions`)
  return response.data as string[]
}

export const useGetUserPermissions = (user: IUser | null) => {
  return useQuery({
    queryKey: ['user_permissions', user?._id],
    queryFn: user ? getUserPermissions : () => Promise.resolve([]),
    staleTime: Infinity,
  })
}

interface IGetResourcesWithPermission {
  action: string
  resource: string
}

export const getResourcesWithPermission = async (
  props: IGetResourcesWithPermission,
): Promise<string[]> => {
  const response = await api().get(`list-permissions`, {
    params: {
      action: props.action,
      resource: props.resource,
    },
  })

  return response.data
}

export const useGetResourcesWithPermission = (props: IGetResourcesWithPermission) => {
  return useQuery({
    queryKey: ['resources_with_permission', props.action, props.resource],
    queryFn: () => getResourcesWithPermission(props),
    staleTime: Infinity,
  })
}

type CheckPermissionProps = {
  action?: string
  resource?: string
}

type CheckPermissionResponse = boolean

export const checkPermission = async ({
  action,
  resource,
}: CheckPermissionProps): Promise<CheckPermissionResponse> => {
  const params = new URLSearchParams()
  if (action) params.append('action', action)
  if (resource) params.append('resource', resource)
  const response = await api().get(`check-permission?${params.toString()}`)
  return response.data?.has_permission
}

export const useCheckPermission = (props: CheckPermissionProps) => {
  return useQuery({
    queryKey: ['check_permission', props],
    queryFn: () => checkPermission(props),
    staleTime: Infinity,
  })
}


export const useGetSignedCookiesFile = () => {
  return useQuery({
    queryKey: 'signed_cookies_file',
    queryFn: async () => {
      const response = await api().post('file-signed-url');
      return response.data;
    },
    staleTime: 2 * 60 * 60 * 1000,
  });
}


export const useGenerateSignedUrl = (fileKey: string) => {
  const { data: signedCookies } = useGetSignedCookiesFile()

  function splitURL(url: string) {
    try {
      const urlObject = new URL(url);

      const domain = `${urlObject.protocol}//${urlObject.host}`;

      const queryParams = urlObject.search;

      return {
        domain,
        queryParams,
        buildURL: (customPath: string): string => {
          const cleanPath = customPath.startsWith('/') ? customPath.slice(1) : customPath;
          return `${domain}/${cleanPath}${queryParams}`;
        }
      };
    } catch (error) {
      return {
        domain: '',
        queryParams: '',
        buildURL: (customPath: string): string => customPath
      };
    }
  }

  const { domain, queryParams } = splitURL(signedCookies);
  if (!domain) return '';

  const signedUrl = `${domain}/${fileKey}${queryParams}`


  return signedUrl
}